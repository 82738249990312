<template>
  <div class="ourBusiness">
    <div class="bg">
      <b-container>
        <div v-if="isShow" class="content">
          <div class="top">
            <div
              v-for="(itemLists, indexLists) in lists"
              :key="indexLists"
              @click="changeTab(indexLists)"
              :class="['top-item', topIndex === indexLists ? 'active' : '']"
            >
              <span> 0{{ indexLists + 1 }}</span
              ><span class="title">{{ itemLists.title }}</span>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <ul>
                <li
                  v-for="(itemLeft, indexLeft) in lists[topIndex].list"
                  :key="indexLeft"
                >
                  <p
                    @click="isleftLi(indexLeft)"
                    :class="leftIndex === indexLeft ? 'active' : ''"
                  >
                    {{ itemLeft.title }}
                  </p>
                  <span v-show="leftIndex === indexLeft">{{
                    itemLeft.content
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="right">
              <div class="imgs">
                <img :src="lists[topIndex].list[leftIndex].src" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mcontent">
          <div
            class="item"
            v-for="(mlistsItem, mlistsIndex) in mlists"
            :key="mlistsIndex"
          >
            <div class="title">{{ mlistsItem.title }}</div>
            <swiper :options="swiperOption" ref="mySwiper">
              <!-- slides -->
              <swiper-slide
                v-for="(cmlItem, cmlIndex) in mlistsItem.list"
                :key="cmlIndex"
              >
                <div class="imgs">
                  <img :src="cmlItem.src" alt="" />
                </div>
                <div class="contents">
                  <p>{{ cmlItem.title }}</p>
                  <span>{{ cmlItem.content }}</span>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev">
                <img src="../../assets/business/mleft.png" alt="" />
              </div>
              <div class="swiper-button-next" slot="button-next">
                <img src="../../assets/business/mright.png" alt="" />
              </div>
            </swiper>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      topIndex: 0, // 案例导航
      leftIndex: 0, // 案例左侧导航
      i: 0, // 右侧活动项index
      index: 0,
      flag: 0, // 左侧显示的标识
      isShow: true,
      // swiperOption: {
      //   observer: true,
      //   notNextTick: true,
      //   slidesPerView: 1, //一行显示3个
      //   spaceBetween: 0, //间隔
      //   freeMode: true,
      //   speed: 500, //滑动速度
      //   slidesOffsetAfter: 0, //最后一个slide与右边界产生了偏移量
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      // },
      lists: [
        {
          title: "虚拟仿真方向",
          list: [
            {
              title: "中学虚拟仿真实验平台",
              content:
                "该项目与人教版携手打造中学虚拟仿真实验平台，是为了初高中教师打造的物理、化学、生物实验操作与演示的DIY仿真实验工具。涵盖初高中八大教材版本的所需实验资源课件，自主研发了四大引擎，拥有千多个实验资源，百多种实验器材，可实现上万种实验操作与演示。",
              src: require("../../assets/business/item01.png"),
            },
            {
              title: "工业机器人智能制造实训",
              content:
                "线上线下混合教学智能制造实验&实训室主要分为三部分：工业机器人教学实训平台 （线下），工业机器人仿真实训平台（线上）、MES制造执行系统（线上/线下混合教学）。",
              src: require("../../assets/business/item02.png"),
            },
            {
              title: "关节镜微创手术虚拟训练平台",
              content:
                "由硬件交互装置和软件系统组成，具有实时、逼真的3D视觉效果和力反馈功能。系统提供了典型的案例模拟（如游离体摘除手术、半月板修复术等），并支持个性化患者的医学影像数据，可用于关节镜手术训练和术前预演。",
              src: require("../../assets/business/item03.png"),
            },
            {
              title: "虚拟康复医疗训练系统",
              content:
                "将根据上肢和下肢的功能障碍程度，一方面提供不同级别的康复训练平台，实施上、下肢的精准康复，另一方面具有功能导向性的VR康复训练环境，提高患者康复训练的兴趣性。",
              src: require("../../assets/business/item04.png"),
            },
            {
              title: "轨道交通车站关键设备健康管理",
              content:
                "选取轨道交通车站对乘客人身安全和运营收益保障影响较大的四类关键设备系统作为典型研究对象（包含：电梯系统、供电系统、自动售检票系统、屏蔽门系统），开展三维可视化故障预警与健康管理技术研究和系统开发。",
              src: require("../../assets/business/item05.png"),
            },
            {
              title: "可移动办税指引平台",
              content:
                "针对税务服务厅面临办税量和接纳量失衡、线上办税入手困难、线上办税知识培训效率低的问题，本项目应用于两部分：本地办税指引和远程专家指引。在本地办税指引中，用户佩戴Hololens眼镜，用语音指令与系统进行交互即可获得办税提示信息；在远程专家指引中，专家和办税人双方可通过语音交流，专家可观察到办税人的网页操作，专家可以发送标注框和文字给办税人的混合现实眼镜。",
              src: require("../../assets/business/item06.png"),
            },
            {
              title: "高校教育项目",
              content:
                "利用VR虚拟现实技术融入到项目当中，让培训岗位或高职院更好学习培训，充分演绎教学实验模式。",
              src: require("../../assets/business/item07.png"),
            },
          ],
        },
        {
          title: "数字化方向",
          list: [
            {
              title: "司法局信息系统国产化",
              content:
                "为健全广州市司法局制度规范，完善流程控制，强化业务衔接，注重信息共享，形成联动机制，实现财务管理和业务职能的有机结合，保障各项工作协同高效发展，需建立广州市司法局内部控制体系，在内部管理、职责分工、业务流程等方面形成相互监督制约的机制，防范经济风险，因此，推动司法政务云国产化当务之急，需开展自主可控产品的研究和应用工作，实现司法领域和司法业务国产化。",
              src: require("../../assets/business/item08.png"),
            },
            {
              title: "南方电网RPA云管控平台项目",
              content:
                "南方电网RPA云管控平台，将构建RPA大数据中心。RPA云管控平台与南方电网的其他业务系统，达到数据的可重复利，可管控，可接入分析，统一数据的一体化建设。将提高决策管理科学性，更好的促进智慧南网建设；对南方电网内其他系统实现互联互通，资源共享。",
              src: require("../../assets/business/item09.png"),
            },
            {
              title: "广东省特种设备检测研究院中山检测院智能派单",
              content:
                "通过完善特种设备的监控，对设备运行产生的数据进行充分的挖掘，通过加装传感器、实时对讲系统、建立自动预警报警平台来解决设备在运行中故障预警、及时救援的问题，最大程度降低设备故障给人民群众带来的生命财产损失，对设备运行数据进行实时记录，监管部门、使用者能够便捷实时掌握设备的运行情况。",
              src: require("../../assets/business/item10.png"),
            },
            {
              title: "南沙区大岗党建",
              content:
                "大岗党建解决了南沙大岗镇政府党员分布广、难集中的在党建工作上开展的难题，集中体现在组织生活的开展、干部队伍的建设、企业文化、党费缴纳等工作上面。",
              src: require("../../assets/business/item11.png"),
            },
            {
              title: "社区网格化管理系统",
              content:
                "本项目替代人工手动录入，用户无需复杂的操作、甚至可以很少打字，只需指指点点就可以高效快速的完成复杂的管理工作。信息采集除非必须键盘输入，全部实现智能导入、鼠标点选录入，最大程度的提高了社区网格的工作效率。",
              src: require("../../assets/business/item12.png"),
            },
          ],
        },
        {
          title: "其他案例积累",
          list: [
            {
              title: "智慧监狱（三维可视化指挥中心）",
              content:
                "虚拟的三维场景和真实的建筑场所（例如监狱，工厂，仓库）联动的系统。",
              src: require("../../assets/business/item13.png"),
            },
            {
              title: "智慧平台（物联网平台）",
              content: "虚拟的三维场景和真实的建筑场所,方便检测和查看。",
              src: require("../../assets/business/item14.png"),
            },
            {
              title: "未来科学大奖",
              content:
                "未来科学大奖成立于2016年，是由科学家、企业家群体共同发起的民间科学奖项。未来科学大奖关注原创性的基础科学研究，奖励在中国大陆（内地）、香港、澳门、台湾做出杰出科技成果的科学家（不限国籍）",
              src: require("../../assets/business/item15.png"),
            },
          ],
        },
      ],
      swiperOption: {
        direction: "horizontal", // 轮播图改为横
        slidesPerView: 1, // 视图显示一个
        spaceBetween: 0, // 子项间距
        mousewheel: true,
        autoHeight: true,
        height: window.innerHeight,
        pagination: {
          // 分页器
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionStart: () => {
            this.i = this.$refs.mySwiper.swiper.activeIndex;
          },
        },
      },
      mlists: [
        {
          title: "虚拟仿真方向",
          list: [
            {
              title: "中学虚拟仿真实验平台",
              content: "该项目与人教版携手打造中学虚拟仿真实验平台，是为了初高中教师打造的物理、化学、生物实验操作与演示的DIY仿真实验工具。涵盖初高中八大教材版本的所需实验资源课件，自主研发了四大引擎，拥有千多个实验资源，百多种实验器材，可实现上万种实验操作与演示。",
              index: 1,
              src: require("../../assets/business/item001.png"),
            },
            {
              title: "工业机器人智能制造实训",
              content: "线上线下混合教学智能制造实验&实训室主要分为三部分：工业机器人教学实训平台 （线下），工业机器人仿真实训平台（线上）、MES制造执行系统（线上/线下混合教学）。",
              index: 1,
              src: require("../../assets/business/item002.png"),
            },
            {
              title: "关节镜微创手术虚拟训练平台",
              content: "由硬件交互装置和软件系统组成，具有实时、逼真的3D视觉效果和力反馈功能。系统提供了典型的案例模拟（如游离体摘除手术、半月板修复术等），并支持个性化患者的医学影像数据，可用于关节镜手术训练和术前预演。",
              index: 2,
              src: require("../../assets/business/item003.png"),
            },
            {
              title: "虚拟康复医疗训练系统",
              content: "将根据上肢和下肢的功能障碍程度，一方面提供不同级别的康复训练平台，实施上、下肢的精准康复，另一方面具有功能导向性的VR康复训练环境，提高患者康复训练的兴趣性。",
              index: 3,
              src: require("../../assets/business/item004.png"),
            },
            {
              title: "轨道交通车站关键设备健康管理",
              content: "选取轨道交通车站对乘客人身安全和运营收益保障影响较大的四类关键设备系统作为典型研究对象（包含：电梯系统、供电系统、自动售检票系统、屏蔽门系统），开展三维可视化故障预警与健康管理技术研究和系统开发。",
              index: 4,
              src: require("../../assets/business/item005.png"),
            },
            {
              title: "可移动办税指引平台",
              content: "针对税务服务厅面临办税量和接纳量失衡、线上办税入手困难、线上办税知识培训效率低的问题，本项目应用于两部分：本地办税指引和远程专家指引。在本地办税指引中，用户佩戴Hololens眼镜，用语音指令与系统进行交互即可获得办税提示信息；在远程专家指引中，专家和办税人双方可通过语音交流，专家可观察到办税人的网页操作，专家可以发送标注框和文字给办税人的混合现实眼镜。",
              index: 5,
              src: require("../../assets/business/item006.png"),
            },
            {
              title: "高校教育项目",
              content: "利用VR虚拟现实技术融入到项目当中，让培训岗位或高职院更好学习培训，充分演绎教学实验模式。",
              index: 6,
              src: require("../../assets/business/item007.png"),
            },
          ],
        },
        {
          title: "数字化方向",
          list: [
            {
              title: "司法局信息系统国产化",
              content: "为健全广州市司法局制度规范，完善流程控制，强化业务衔接，注重信息共享，形成联动机制，实现财务管理和业务职能的有机结合，保障各项工作协同高效发展，需建立广州市司法局内部控制体系，在内部管理、职责分工、业务流程等方面形成相互监督制约的机制，防范经济风险，因此，推动司法政务云国产化当务之急，需开展自主可控产品的研究和应用工作，实现司法领域和司法业务国产化。",
              index: 7,
              src: require("../../assets/business/item008.png"),
            },
            {
              title: "南方电网RPA云管控平台项目",
              content: "南方电网RPA云管控平台，将构建RPA大数据中心。RPA云管控平台与南方电网的其他业务系统，达到数据的可重复利，可管控，可接入分析，统一数据的一体化建设。将提高决策管理科学性，更好的促进智慧南网建设；对南方电网内其他系统实现互联互通，资源共享。",
              index: 8,
              src: require("../../assets/business/item009.png"),
            },
            {
              title: "广东省特种设备检测研究院中山检测院智能派单",
              content: "通过完善特种设备的监控，对设备运行产生的数据进行充分的挖掘，通过加装传感器、实时对讲系统、建立自动预警报警平台来解决设备在运行中故障预警、及时救援的问题，最大程度降低设备故障给人民群众带来的生命财产损失，对设备运行数据进行实时记录，监管部门、使用者能够便捷实时掌握设备的运行情况。",
              index: 9,
              src: require("../../assets/business/item010.png"),
            },
            {
              title: "南沙区大岗党建",
              content: "大岗党建解决了南沙大岗镇政府党员分布广、难集中的在党建工作上开展的难题，集中体现在组织生活的开展、干部队伍的建设、企业文化、党费缴纳等工作上面。",
              index: 10,
              src: require("../../assets/business/item011.png"),
            },
            {
              title: "社区网格化管理系统",
              content: "本项目替代人工手动录入，用户无需复杂的操作、甚至可以很少打字，只需指指点点就可以高效快速的完成复杂的管理工作。信息采集除非必须键盘输入，全部实现智能导入、鼠标点选录入，最大程度的提高了社区网格的工作效率。",
              index: 11,
              src: require("../../assets/business/item012.png"),
            },
          ],
        },
        {
          title: "其他案例积累",
          list: [
            {
              title: "智慧监狱（三维可视化指挥中心）",
              content: "虚拟的三维场景和真实的建筑场所（例如监狱，工厂，仓库）联动的系统。",
              index: 12,
              src: require("../../assets/business/item013.png"),
            },
            {
              title: "智慧平台（物联网平台）",
              content: "虚拟的三维场景和真实的建筑场所,方便检测和查看。",
              index: 13,
              src: require("../../assets/business/item014.png"),
            },
            {
              title: "未来科学大奖",
              content: "未来科学大奖成立于2016年，是由科学家、企业家群体共同发起的民间科学奖项。未来科学大奖关注原创性的基础科学研究，奖励在中国大陆（内地）、香港、澳门、台湾做出杰出科技成果的科学家（不限国籍）",
              index: 14,
              src: require("../../assets/business/item015.png"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    // 切换案例tab
    changeTab(index) {
      this.topIndex = index;
      this.leftIndex = 0;
    },
    // 点击左侧的小标题
    isleftLi(index) {
      this.leftIndex = null;
      this.leftIndex = index;
    },
  },
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    currentList() {
      let arr = this.lists.map((item) => item.list);
      arr = arr.flat();
      return arr;
    },
  },
  created() {
    let screen = document.body.clientWidth;
    if (screen > 768) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.swiper-dots {
  height: 30px;
  .swiper-dot {
    width: 20px;
    height: 20px;
    background-color: red;
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  left: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.container {
  padding: 0;
}
.ourBusiness {
  margin-top: 80px;
  .bg {
    width: 100%;
    background: url("../../assets/business/bg.png");
    background-size: cover;
    .content {
      width: 100%;
      padding-top: 105px;
      .top {
        display: flex;
        justify-content: space-between;
        text-align: center;
        cursor: pointer;
        .top-item {
          font-size: 32px;
          padding: 5px 10px;
          font-weight: 700;
          color: #8e96a4;
          &.active {
            color: #333333;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0px 2px 6px 0px rgba(26, 148, 255, 0.16);
          }
          .title {
            padding-left: 20px;
          }
        }
      }
      .item {
        margin-top: 160px;
        margin-bottom: 268px;
        display: flex;
        .left {
          width: 27.25%;
          ul {
            li {
              margin-top: 1em;
              padding-left: 1.5em;
              cursor: pointer;
              p {
                position: relative;
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                &::before {
                  position: absolute;
                  left: -1.5em;
                  top: 50%;
                  transform: translateY(-50%);
                  content: "";
                  display: block;
                  width: 4px;
                  height: 4px;
                  background: #333333;
                  border-radius: 50%;
                }
              }
              .active {
                color: #3c8cff;
              }
              .active::before {
                position: absolute;
                left: -1.6em;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                display: block;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: 1px solid #3c8cff;
                background: transparent;
              }
              span {
                font-size: 12px;
                font-weight: Normal;
                color: #3c8cff;
              }
            }
          }
        }
        .right {
          width: 72.75%;
          .imgs {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .ourBusiness {
    .bg {
      .content {
        padding-top: 50px;
        .item {
          margin-top: 60px;
          margin-bottom: 120px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .swiper-button-next:after {
    content: "";
  }
  .swiper-button-prev:after {
    content: "";
  }
  .swiper-button-prev {
    top: 100%;
    left: 75%;
  }
  .swiper-button-next {
    top: 100%;
    right: 15px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 14px;
    img {
      width: 100%;
      display: block;
    }
  }
  .ourBusiness {
    margin-top: 60px;
    .bg {
      .mcontent {
        padding: 0 15px;
        width: 100%;
        .item {
          margin-bottom: 48px;
          width: 100%;
          .title {
            position: relative;
            display: inline-block;
            text-indent: 0.5em;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            vertical-align: top;
            &::before {
              position: absolute;
              left: 0;
              top: 5px;
              content: "";
              display: block;
              width: 0;
              height: 14px;
              border-left: 2px solid #3c8cff;
            }
          }
          .swiper-container {
            position: relative;
            margin-top: 15px;
            width: 100%;
            overflow: hidden;
            .swiper-wrapper {
              width: 100%;
              .swiper-slide {
                position: relative;
                width: 100%;
                .imgs {
                  width: 100%;
                  img {
                    width: 100%;
                  }
                }
                .contents {
                  text-align: left;
                  width: 100%;
                  padding: 100px 15px 30px;
                  position: absolute;
                  left: 0;
                  bottom: 0px;
                  background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0),
                    #000000
                  );
                  p {
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                    color: #ffffff;
                  }
                  span {
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>


